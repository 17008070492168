import React from 'react';
import { Link } from 'gatsby';
import Lottie from 'lottie-react';
import { createStyles, Title, Text, Button, Container, Group, Center } from '@mantine/core';

import { Layout } from '../components/Layout/Layout';
import space from '../animations/space.json';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  label: {
    textAlign: 'center',
    userSelect: 'none',
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: theme.spacing.xl * 1.5,
    color: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[4],

    [theme.fn.smallerThan('sm')]: {
      fontSize: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));

const NotFoundPage = () => {
  const { classes } = useStyles();

  return (
    <Layout>
      <Container className={classes.root}>
        <Center>
          <Lottie animationData={space} loop autoPlay />
        </Center>
        <div className={classes.label}>404</div>
        <Title className={classes.title}>You have found a secret place.</Title>
        <Text color="dimmed" size="lg" align="center" className={classes.description}>
          Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has
          been moved to another URL.
        </Text>
        <Group position="center">
          <Button component={Link} to="/" variant="subtle" size="md">
            Take me back to home page
          </Button>
        </Group>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
